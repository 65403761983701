import Luukje from "./Luukje";
import Annijtje from "./Annijtje";
import Kieke from "./Kieke";
import Doetje from "./Doetje";
import Pietje from "./Pietje";
import Aafje from "./Aafje";
import Eefje from "./Eefje";
import Yfke from "./Yfke";
import Maaike from "./Maaike";
import Hylke from "./Hylke";
import Ineke from "./Ineke";
import Janneke from "./Janneke";
import Lonneke from "./Lonneke";
import Floortje from "./Floortje";
import Trijntje from "./Trijntje";
import Hendrikje from "./Hendrikje";
import Verleitje from "./Verleitje";
import Juultje from "./Juultje";
import Wiesje from "./Wiesje";
import Raalfje from "./Raalfje";
import Neeltje from "./Neeltje";
import Dirkje from "./Dirkje";
import Gertje from "./Gertje";
import Katrientje from "./Katrientje";
import Bertje from "./Bertje";
import Colintje from "./Colintje";
import Dennisje from "./Dennisje";
import Elsje from "./Elsje";
import Liesje from "./Liesje";
import Laurtje from "./Laurtje";
import Maurtje from "./Maurtje";
import Marijke from "./Marijke";
import Roosje from "./Roosje";
import Sybje from "./Sybje";
import Sylweitje from "./Sylweitje";
import Taimurtje from "./Taimurtje";
import Timmetje from "./Timmetje";
import Truusje from "./Truusje";
import Wilempje from "./Wilempje";
import Blijpje from "./Blijpje";
import Blijpje2 from "./Blijpje2";
import Blijpje3 from "./Blijpje3";
import Blijpje4 from "./Blijpje4";
import Bonnetje from "./Bonnetje";
import Bonnetje2 from "./Bonnetje2";
import Appeltje from "./Appeltje";
import Citroentje from "./Citroentje";
import Futurtje from "./Futurtje";
// import Katje from "./Katje";
// import Katje2 from "./Katje2";
// import Katje3 from "./Katje3";
// import Katje4 from "./Katje4";

export const kwastjes = {
  Luukje,
  Annijtje,
  Kieke,
  Doetje,
  Pietje,
  Aafje,
  Eefje,
  Yfke,
  Maaike,
  Laurtje,
  Hylke,
  Ineke,
  Janneke,
  Lonneke,
  Floortje,
  Trijntje,
  Hendrikje,
  Verleitje,
  Juultje,
  Wiesje,
  Raalfje,
  Neeltje,
  Dirkje,
  Gertje,
  Katrientje,
  Bertje,
  Colintje,
  Dennisje,
  Elsje,
  Liesje,
  Maurtje,
  Marijke,
  Roosje,
  Sybje,
  Sylweitje,
  Taimurtje,
  Timmetje,
  Truusje,
  Wilempje,
  Blijpje,
  Blijpje2,
  Blijpje3,
  Blijpje4,
  Bonnetje,
  Bonnetje2,
  Appeltje,
  Citroentje,
  Futurtje,
  // Katje,
  // Katje2,
  // Katje3,
  // Katje4,
};
